import React from "react"
import { Link, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"

import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description

    const posts = data.allContentfulPosts.edges

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO
          title="Home"
          keywords={["blog", "gatsby", "javascript", "react"]}
        />

        <h2>What's it all about...</h2>
        <p>
          My mind is getting overloaded with ideas, stories and plans. So I have
          decide to let some of the drivel seep out for anyone that finds
          themselves on this site to either love or hate.
        </p>

        <p>
          The only other way I can explain this site is to call it a blog with
          extras. By extras I mean this site will contain my ideas, creative
          writing, piece of text I'm currently reading, some of my sketches and
          anything else I feel like sharing.
        </p>

        <h2>Recent Posts</h2>

        <div className="postListing">
          {posts.map(({ node }) => {
            let preslug
            if (node.type === "article") {
              preslug = "/article/"
            }
            if (node.type === "blog") {
              preslug = "/blog/"
            }
            if (node.type === "story") {
              preslug = "/story/"
            }

            const title = node.title || node.slug
            const url = preslug + node.slug
            return (
              <div key={node.slug} className={node.type}>
                <Img fixed={node.featuredImage.fixed} />
                <span className="type">{node.type}</span>
                <div className="content">
                  <h3
                    style={{
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link style={{ boxShadow: "none" }} to={url}>
                      {title}
                    </Link>
                  </h3>
                  <small>{node.date}</small>
                  <p
                    style={{
                      fontSize: rhythm(1 / 2),
                    }}
                    dangerouslySetInnerHTML={{
                      __html: node.body.childMarkdownRemark.excerpt,
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulPosts(sort: { fields: [date], order: DESC }, limit: 10) {
      edges {
        node {
          id
          title
          slug
          type
          date(formatString: "MMMM Do, YYYY")
          featuredImage {
            fixed(width: 300) {
              src
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 250)
            }
          }
        }
      }
    }
  }
`
